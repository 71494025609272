* {
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    height:100vh;
    width:290mm;
    margin-right: auto;
    margin-left: auto;
}
.button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

tr td { width:25%; }

#clearBtn {
    background-color: #f44336; /* Red */
    margin-left:20px;
}

#back_link {
    color: white;
}

.container {
    width: 100%;
    height: 100%;
    top: 10%;
    left: 10%;
  }
  
.sigContainer {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;
    border-color: black;
}

.sigPad {
    width: 100%;
    height: 100%;
}

.buttons {
    width: 100%;
    height: 30px;
}

.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
}